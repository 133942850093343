.rc-virtual-list-holder-inner {
  background-color: rgba(31, 72, 231, 0.05) !important;
  div {
    font-size: 1rem !important;
    padding: 0.3125rem 0.6875rem !important;
    &:hover {
      background-color: rgba(31, 72, 231, 0.05) !important;
    }
  }
}

@import "~antd/dist/antd.less";
@drawer-header-padding: 17px 24px;