@font-face {
  font-family: "Bebas";
  src: local("Bebas"), url(./assets/fonts/BEBAS.ttf) format("truetype");
}

.landing-anchor:before {
  content: "";
  display: block;
  position: relative;
  width: 0;
  height: 110px;
  margin-top: -80px;
}
